<template>
  <div class="prod-variants">
    <!-- Loading State -->
    <div class="flex flex-col items-stretch gap-5" v-if="isLoading">
      <div
        class="flex flex-col items-stretch p-4 gap-3 bg-white rounded-lg shadow-box overflow-hidden"
        v-for="y in 2"
        :key="y"
      >
        <div class="flex items-center justify-between" v-for="x in 5" :key="x">
          <div class="flex items-center space-x-3">
            <div class="loader w-10 h-10"></div>
            <p class="loader">XXXX XXX</p>
          </div>
          <div class="w-16 h-4 loader"></div>
        </div>
      </div>
    </div>

    <!-- Bundles -->
    <template v-else-if="isBundle && isBundlePublished">
      <Disclosure
        as="div"
        class="prod-variants-cart"
        v-for="(v, i) in getVariants"
        :key="v.id"
        v-slot="{ open }"
      >
        <div class="prod-variant-header">
          <p>{{ v.opt1 || `Bundle ${i + 1}` }}</p>
          <DisclosureButton>
            <Icon name="package-outline" class="w-3.5 h-3.5" />
            <span>View Products</span>
          </DisclosureButton>
        </div>
        <div class="flex flex-col px-5 pt-2 pb-4">
          <div class="bundle-title save-label" v-if="v.stockLevelAbsolute >= 0">
            <Icon name="lightning" class="w-3.5 h-3.5" />
            <span
              >Save
              <span v-if="v.price > 0">{{ v.bundle_discount }}% </span>
              in this bundle</span
            >
          </div>
          <Item
            :isLoggedIn="isLoggedIn"
            :variant="v"
            :bulk="getData.bulk_discount"
            :catRank="getData.rank"
            :brandRank="getData.rank_brand"
            :product="getProduct"
            :productsQty="
              getProduct.variants.map((r) => r.qty).reduce((a, b) => a + b)
            "
            @image-click="handleEvent"
          />
        </div>
        <DisclosureButton
          v-if="open"
          class="flex items-center justify-between px-4 py-3 border-b border-theme-bg-shade border-opacity-30 w-full border-t mt-2"
        >
          <p class="flex items-center text-sm text-theme-dark font-medium">
            Bundle Contents ({{ v.bundle_name }})
          </p>
          <ChevronUpIcon
            :class="!open ? 'transform rotate-180' : ''"
            class="w-5 h-5 text-theme-body"
          />
        </DisclosureButton>
        <DisclosurePanel>
          <BundleItems :items="v.bundle_items" />
        </DisclosurePanel>
      </Disclosure>
    </template>

    <!-- Variants -->
    <template v-else>
      <Disclosure
        as="div"
        v-slot="{ open }"
        :defaultOpen="true"
        class="flex flex-col bg-white rounded-lg shadow-box overflow-hidden"
        v-if="showBulkPricing"
      >
        <DisclosureButton
          class="flex items-center justify-between px-4 py-3 border-b border-theme-bg-shade border-opacity-30 w-full"
        >
          <p class="flex items-center text-sm text-theme-secondary font-medium">
            <Icon name="package" class="pkg-icon mr-1.5" />
            Bulk Pricing
          </p>
          <ChevronUpIcon
            :class="!open ? 'transform rotate-180' : ''"
            class="w-5 h-5 text-theme-body"
          />
        </DisclosureButton>
        <DisclosurePanel>
          <div class="bulk-pricing-modal inline-info no-shadow">
            <div class="bulk-pricing-header">
              <p>Automatic volume discounts</p>
            </div>
            <div class="bulk-pricing-content">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-white">
                  <tr>
                    <th scope="col">QUANTITY</th>
                    <th scope="col">DISCOUNT</th>
                    <th scope="col">PRICE</th>
                  </tr>
                </thead>
                <tbody v-for="bd in getData.bulk_discount" :key="bd.id">
                  <tr>
                    <td class="font-medium">{{ bd.min_quantity }}+</td>
                    <td>{{ Number(bd.discount_percentage).toFixed(2) }}%</td>
                    <td>
                      ${{
                        Number(
                          getData.product.variants[0].price -
                            (getData.product.variants[0].price / 100) *
                              bd.discount_percentage
                        ).toFixed(2)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>

      <div class="prod-variants-cart">
        <div class="prod-variant-header">
          <p>{{ getProduct.opt1 || "Variants" }}</p>
          <button v-if="hasBulkPricing" @click="showBulkPricing = true">
            <Icon name="package-outline" class="w-3.5 h-3.5" />
            <span>Bulk Pricing</span>
          </button>
        </div>
        <div class="p-5 space-y-5">
          <div
            v-for="v in getVariants"
            :key="v.id"
            class="flex flex-col space-y-5"
          >
            <Item
              :isLoggedIn="isLoggedIn"
              :variant="v"
              :bulk="getData.bulk_discount"
              :catRank="getData.rank"
              :brandRank="getData.rank_brand"
              :product="getProduct"
              :productsQty="
                getProduct.variants.map((r) => r.qty).reduce((a, b) => a + b)
              "
              @image-click="handleEvent($event)"
            />
          </div>
        </div>
      </div>
    </template>

    <!-- Compatibles -->
    <div v-if="getCompatibles.length > 0" class="prod-compatibles">
      <Disclosure v-slot="{ open }" :defaultOpen="true">
        <DisclosureButton
          class="flex items-center justify-between px-4 py-3 border-b border-theme-bg-shade border-opacity-30 w-full"
        >
          <p class="text-base text-theme-secondary font-medium">
            Compatible with
          </p>
          <ChevronUpIcon
            :class="!open ? 'transform rotate-180' : ''"
            class="w-5 h-5 text-theme-body"
          />
        </DisclosureButton>
        <DisclosurePanel class="divide-y divide-theme-border divide-opacity-50">
          <div
            v-for="compatible in getCompatibles"
            :key="compatible.id"
            class="flex flex-col space-y-5 p-5"
          >
            <!-- Group Title -->
            <h3
              class="text-sm font-normal text-theme-body uppercase flex-shrink-0"
            >
              <router-link :to="compatible.fullUrl">{{
                compatible.name
              }}</router-link>
            </h3>
            <!-- Group Item -->
            <div v-for="v in compatible.variants" :key="v.id">
              <Item
                :is-linked="true"
                :isLoggedIn="isLoggedIn"
                :variant="v"
                :catRank="getData.rank"
                :brandRank="getData.rank_brand"
                :product="getProduct"
              />
            </div>
          </div>
          <div
            v-if="compatibleProds.length > 3"
            @click="showAllComps = !showAllComps"
            class="toggle-view-btn"
          >
            <span>View {{ showAllComps ? "Less" : "All" }}</span>
            <Icon
              name="down-arrow"
              class="w-5 h-5"
              :class="showAllComps ? 'transform rotate-180' : ''"
            />
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  </div>
</template>

<script>
import clone from "lodash/clone";
import { computed, ref } from "vue";
import Item from "../../common/Item";
import BundleItems from "./BundleItems.vue";
import { ChevronUpIcon } from "@heroicons/vue/solid";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

export default {
  name: "ProductVariants",
  components: {
    Item,
    BundleItems,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon,
  },
  emits: ["imageClick"],
  props: {
    data: {
      type: Object,
      required: true,
    },
    compatibles: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    isBundle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const getData = computed(() => props.data);

    const showBulkPricing = ref(false);
    const showAllComps = ref(false);

    const hasBulkPricing = computed(
      () => getData.value.bulk_discount?.length > 0 && props.isLoggedIn
    );

    const getProduct = computed(() => getData.value.product || {});

    const isBundlePublished = computed(
      () => !getProduct.value?.bundle_published
    );

    const getVariants = computed(() => {
      const prods = getProduct.value.variants.map((v) => {
        let total = 0;
        v.bundle_items?.forEach((i) => {
          total += i.quantity * i.wholesale_price;
        });
        const bundleItems = props.isBundle ? v.bundle_items : [];
        const bundleItemsLength = bundleItems.length;
        return {
          ...v,
          bundle_discount: Math.floor(100 - (v.price / total) * 100),
          bundle_name: props.isBundle
            ? `${bundleItemsLength} Product${bundleItemsLength > 1 ? "s" : ""}`
            : null,
        };
      });
      prods.sort((a, b) => {
        let x = parseInt(a.opt1) ? parseInt(a.opt1.trim()) : a.opt1.trim();
        let y = parseInt(b.opt1) ? parseInt(b.opt1.trim()) : b.opt1.trim();
        return x < y ? -1 : x > y ? 1 : 0;
      });

      const inStock = prods.filter((r) => r.available_stock != 0);
      return new Set([...inStock, ...prods]);
    });

    const handleEvent = (varImg, i = "") => {
      emit("imageClick", varImg, i);
    };

    const compatibleProds = computed(() =>
      (props.compatibles?.data || []).map((c) => {
        const fullUrl = `${process.env.BASE_URL.replace(
          /\/$|$/,
          "/"
        )}products/${c.slug}`;
        return {
          ...c,
          fullUrl,
        };
      })
    );

    const getCompatibles = computed(() =>
      showAllComps.value
        ? compatibleProds.value
        : clone(compatibleProds.value).splice(0, 3)
    );

    return {
      getData,
      getProduct,
      handleEvent,
      getVariants,
      showAllComps,
      getCompatibles,
      hasBulkPricing,
      showBulkPricing,
      compatibleProds,
      isBundlePublished,
    };
  },
};
</script>

<style lang="scss" scoped>
.prod-variants {
  @apply flex flex-col gap-8;

  .prod-variants-cart,
  .prod-compatibles {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    @apply rounded-lg bg-white overflow-hidden;
  }

  .prod-variant-header {
    @apply flex items-center justify-between px-4 py-2 border-b border-theme-bg-shade border-opacity-30;

    p {
      @apply text-xs text-brand-secondary uppercase tracking-wide;
    }

    button {
      @apply text-xs text-theme-secondary flex items-center space-x-1 border-b border-dashed border-theme-secondary;
    }
  }

  .loader {
    @apply inline-block text-transparent bg-gray-100 animate-pulse rounded-md;
  }
}
</style>
