<template>
  <div class="join-info">
    <h2>Benefits of becoming a member.</h2>
    <div class="items-grid">
      <div class="item">
        <div class="icon">
          <Icon name="high-quality" />
        </div>
        <div>
          <p>The Best Products</p>
          <span>{{ commaFormat(totalCount) }} of them and growing.</span>
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <Icon name="easy-payments" />
        </div>
        <div>
          <p>Payment Options</p>
          <span>Pay now or pay later.</span>
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <Icon name="top-brands" />
        </div>
        <div>
          <p>Super fast Shipping</p>
          <span>Most orders ship same day.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import Mymixin from "@/modules/mixins";

export default {
  name: "JoinUsInfo",
  mixins: [Mymixin],
  setup() {
    const axios = inject("axios");
    var totalCount = ref("0");
    function variantCounts() {
      axios.api
        .get("/variants-count")
        .then((res) => (totalCount.value = res.data));
    }
    variantCounts();
    return {
      totalCount,
    };
  },
};
</script>

<style lang="scss" scoped>
.join-info {
  @apply w-full bg-white rounded-lg p-6 flex flex-col;

  .items-grid {
    @apply grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 xl:gap-6;
  }
  h2 {
    @apply block text-base font-semibold text-theme-secondary mb-5;
  }
  .item {
    @apply flex items-center;
    .icon {
      @apply flex-shrink-0 w-12 h-12 mr-4;
      svg {
        @apply w-full h-full;
      }
    }
    p {
      @apply text-black text-base font-semibold;
    }
    span {
      @apply text-theme-light;
    }
  }
}
</style>
