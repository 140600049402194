<template>
  <div class="product-page w-full max-container">
    <div class="product-section">
      <div class="product-info-grid">
        <div class="product-info">
          <div class="product-preview">
            <div class="white-block">
              <!-- Product Title / Mobile-->
              <ProductTitle
                v-if="isOnMobile"
                :info="product?.data || {}"
                :isLoading="!isProductLoaded"
                :isFavorite="product.watchlist !== 0 && isProductLoaded"
                :isLoggedIn="isLoggedIn"
                @onFavClick="addToWatchList()"
                class="mb-5"
              />

              <!-- Gallery Images -->
              <ProductImages
                :isLoading="!isProductLoaded"
                :images="product.data?.product_images || []"
                :defaultImg="product.data?.product?.image_url"
                :changedImg="variantGalleryItem"
              />
            </div>

            <Rankings
              v-if="isProductLoaded && !isOnMobile"
              :isLoggedIn="isLoggedIn"
              :data="product?.data || {}"
            />

            <div
              v-if="
                product.data.last_order &&
                  product.data.last_order != null &&
                  isOnTablet &&
                  !isOnMobile
              "
            >
              <div
                class="flex items-center p-4 bg-white justify-center space-x-2 rounded-lg shadow-box overflow-hidden"
                v-for="lorder in product.data.last_order
                  ? product.data.last_order.slice(0, 1)
                  : null"
                :key="lorder.id"
              >
                <p class="text-md text-theme-body font-light">
                  Last ordered on
                  <span class="font-semibold text-theme-secondary">{{
                    moment(lorder.created_at).format("L")
                  }}</span>
                </p>
                <span
                  @click="showOrderInfo = !showOrderInfo"
                  class="text-theme-body hover:text-theme-dark-shade cursor-pointer"
                  ><Icon name="info" class="w-4 h-4 "
                /></span>
              </div>
            </div>
          </div>
          <!-- On Tablet View -->
          <div
            class="flex-grow flex flex-col gap-5 w-full 2xl:w-auto"
            v-if="isOnTablet"
          >
            <!-- Product Title / Desktop-->
            <ProductTitle
              :info="product?.data || {}"
              :isLoading="!isProductLoaded"
              :isFavorite="product.watchlist !== 0 && isProductLoaded"
              :isLoggedIn="isLoggedIn"
              @onFavClick="addToWatchList()"
              class="has-padding white-block"
              v-if="!isOnMobile"
            />

            <!-- Product Description -->
            <div class="white-block no-padding">
              <ProductInfo
                :isLoading="!isProductLoaded"
                :description="product.data.product?.description"
                :isMinimized="true"
              />
            </div>

            <div
              class="product-category-cards"
              v-if="
                isLoggedIn &&
                  isProductLoaded &&
                  product.data?.product &&
                  isOnTablet
              "
            >
              <!-- Variants, Brand and Compatibles -->
              <ProductExtra class="col-span-full">
                <ProductVariants
                  :data="product.data"
                  :isLoading="!isProductLoaded"
                  :isBundle="isBundlePage"
                  :isLoggedIn="isLoggedIn"
                  :compatibles="compatible"
                  @imageClick="variantImageClick"
                />
              </ProductExtra>
              <CategoryCard
                :linkFilter="product.data.product"
                :firstline="
                  `More ${product.data.product.product_type || ''} from`
                "
                :secondline="product.data.product.brand || ''"
                image-position="left"
                cta="View All"
                :route="
                  slugUrl(
                    `category/${
                      product?.data
                        ? product.data.product.category?.slug
                        : ''
                    }`
                  )
                "
                :product="product.data.product.product_type || null"
                :brand="product.data.product.brand || null"
              >
                <div
                  class="flex items-center justify-center flex-shrink-0 w-20 h-20 border border-gray-100 rounded-full p-1"
                >
                  <img
                    class="max-w-full w-auto"
                    v-if="
                      getBrandImg(
                        product.data.product.product_brand?.image || null
                      )
                    "
                    :src="
                      getBrandImg(
                        product.data.product.product_brand?.image || null
                      )
                    "
                  />
                </div>
              </CategoryCard>
              <CategoryCard
                class="shaded"
                :title="product.data.product.category?.name || ''"
                :route="
                  slugUrl(
                    `category/${product.data.product.category?.slug || ''}`
                  )
                "
              >
                <img
                  class="max-h-24 w-auto rounded-full"
                  v-if="
                    product.data.product_images &&
                      product.data.product_images[0]
                  "
                  :src="
                    product.data.product_images[0].base_path +
                      '/' +
                      product.data.product_images[0].file_name
                  "
                />
              </CategoryCard>
            </div>
          </div>
          <div class="product-desc white-block has-padding" v-else>
            <!-- Product Title / Desktop-->
            <ProductTitle
              v-if="!isOnMobile"
              :info="product?.data || {}"
              :isLoading="!isProductLoaded"
              :isFavorite="product.watchlist !== 0 && isProductLoaded"
              :isLoggedIn="isLoggedIn"
              @onFavClick="addToWatchList()"
            />

            <!-- Product Description -->
            <ProductInfo
              :isLoading="!isProductLoaded"
              :description="product.data.product?.description"
              :isMinimized="isOnMobile || isOnTablet"
            />
          </div>
        </div>
        <div
          class="product-category-cards"
          v-if="
            isLoggedIn &&
              isProductLoaded &&
              product.data?.product &&
              !isOnTablet
          "
        >
          <!-- Variants, Brand and Compatibles -->
          <ProductExtra class="col-span-full" v-if="isOnTablet">
            <ProductVariants
              :data="product.data"
              :isLoading="!isProductLoaded"
              :isBundle="isBundlePage"
              :isLoggedIn="isLoggedIn"
              :compatibles="compatible"
              @imageClick="variantImageClick"
            />
          </ProductExtra>
          <CategoryCard
            :linkFilter="product.data.product"
            :firstline="`More ${product.data.product.product_type || ''} from`"
            :secondline="product.data.product.brand || ''"
            image-position="left"
            cta="View All"
            :route="
              slugUrl(
                `category/${
                  product?.data ? product.data.product.category?.slug : ''
                }`
              )
            "
            :product="product.data.product.product_type || null"
            :brand="product.data.product.brand || null"
          >
            <div
              class="flex items-center justify-center flex-shrink-0 w-20 h-20 border border-gray-100 rounded-full p-1"
            >
              <img
                class="max-w-full w-auto"
                v-if="
                  getBrandImg(product.data.product.product_brand?.image || null)
                "
                :src="
                  getBrandImg(product.data.product.product_brand?.image || null)
                "
              />
            </div>
          </CategoryCard>
          <CategoryCard
            class="shaded"
            :title="product.data.product.category?.name || ''"
            :route="
              slugUrl(
                `category/${product.data.product.category?.slug || ''}`
              )
            "
          >
            <img
              class="max-h-24 w-auto rounded-full"
              v-if="
                product.data.product_images && product.data.product_images[0]
              "
              :src="
                product.data.product_images[0].base_path +
                  '/' +
                  product.data.product_images[0].file_name
              "
            />
          </CategoryCard>
        </div>
        <JoinusInfo v-if="!isLoggedIn" />
      </div>

      <!-- Variants, Brand and Compatibles -->
      <ProductExtra v-if="!isOnTablet">
        <BrandCard
          :isLoading="!isProductLoaded"
          :url="slugUrl('brand/' + product.data.brand_slug)"
          :img="getBrandImg(product.data.product?.product_brand?.image || null)"
          :name="product.data.product ? product.data.product.brand : 'Brand'"
        />
        <div v-if="product.data.last_order && product.data.last_order != null">
          <div
            class="flex items-center p-4 bg-white justify-center space-x-2 rounded-lg shadow-box overflow-hidden"
            v-for="lorder in product.data.last_order
              ? product.data.last_order.slice(0, 1)
              : null"
            :key="lorder.id"
          >
            <p class="text-md text-theme-body font-light">
              Last ordered on
              <span class="font-semibold text-theme-secondary">{{
                moment(lorder.created_at).format("L")
              }}</span>
            </p>
            <span
              @click="showOrderInfo = !showOrderInfo"
              class="text-theme-body hover:text-theme-dark-shade cursor-pointer"
              ><Icon name="info" class="w-4 h-4 "
            /></span>
          </div>
        </div>

        <ProductVariants
          :data="product.data"
          :isLoading="!isProductLoaded"
          :isBundle="isBundlePage"
          :isLoggedIn="isLoggedIn"
          :compatibles="compatible"
          @imageClick="variantImageClick"
        />
      </ProductExtra>
    </div>

    <ModalFull
      :isVisible="showOrderInfo"
      :resetContainer="true"
      containerClass="w-full max-w-xs"
    >
      <div
        class="flex items-start flex-col py-5 px-6 bg-white rounded shadow-darkbg overflow-hidden w-full"
      >
        <p
          class="text-base font-medium text-theme-secondary mb-5 flex items-center space-x-2"
        >
          <Icon name="info" class="w-4.5 h-4.5" />
          <span>Last order Info</span>
        </p>
        <ul
          class="flex flex-col text-md text-theme-body space-y-1.5"
          v-for="lorder in product.data.last_order"
          :key="lorder.id"
        >
          <li>{{ lorder.name }} x{{ lorder.quantity }}</li>
        </ul>
        <div class="flex flex-col items-end mt-5 w-full">
          <span
            @click="showOrderInfo = false"
            class="text-sm text-right cursor-pointer text-theme-body hover:text-theme-secondary"
            >Close</span
          >
        </div>
      </div>
    </ModalFull>
  </div>
</template>

<script>
import { ref, computed, nextTick, watch, onMounted } from "vue";
import Product from "@/modules/Product";
import CategoryCard from "./modules/CategoryCard.vue";
import JoinusInfo from "./modules/JoinUsInfo.vue";
import Mymixin from "@/modules/mixins";
import Rankings from "./modules/Rankings.vue";
import BrandCard from "./modules/BrandCard.vue";
import { useRoute } from "vue-router";

import ProductTitle from "./modules/ProductTitle.vue";
import ProductImages from "./modules/ProductImages.vue";
import ProductInfo from "./modules/ProductInfo.vue";
import ProductExtra from "./modules/ProductExtra.vue";
import ProductVariants from "./modules/ProductVariants.vue";

import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { useStore } from "vuex";

export default {
  components: {
    CategoryCard,
    JoinusInfo,
    Rankings,
    BrandCard,
    ProductTitle,
    ProductImages,
    ProductInfo,
    ProductExtra,
    ProductVariants,
  },
  name: "Product",
  mixins: [Mymixin],
  inheritAttrs: false,
  beforeMount() {
    this.$store.dispatch("getCart", { ax: null });
  },
  beforeUnmount() {
    if(this.$route.name != "Product" && this.$route.name != "ProductBundles"){
      this.$store.dispatch("setList", {});
    }
  },
  methods: {
    moment: function(date) {
      return moment(date);
    },
  },
  setup() {
    const showReadMore = ref(false);
    const showOrderInfo = ref(false);
    const route = useRoute();
    const store = useStore();

    const breakpoints = useBreakpoints(breakpointsTailwind);
    const isOnTablet = breakpoints.smallerOrEqual("2xl");
    const isOnMobile = breakpoints.smaller("xl");

    const variantGalleryItem = ref(null);

    const getBrandImg = (image) => {
      if (image == null) {
        return "/images/no-image.png";
      }
      return (
        "https://d3k6t6l60lmqbi.cloudfront.net/" +
        product.data.product.product_brand.image.path
      );
    };

    const slugUrl = (slug) => {
      const base_url = process.env.BASE_URL || "/";
      return base_url + slug;
    };

    const isBundlePage = computed(() => route.name == "ProductBundles");

    const {
      product,
      getProduct,
      compatible,
      getCompatibleproducts,
      addtowatchlist: addToWatchList,
      watchlist,
      isLoggedIn,
    } = Product();

    getProduct();
    getCompatibleproducts();

    const isProductLoaded = computed(() => {
      if (!product.loading) {
        return true;
      }
      return false;
    });

    const variantImageClick = (varImg, i = "") => {
      if (!varImg) return;

      const imageFileName = varImg
        .split("/")
        .pop()
        .replace("thumbnail_", "");
      variantGalleryItem.value = imageFileName;
    };

    const currentShipping = computed(() => store.getters.getSelectedAddress);

    watch(currentShipping, () => {
      if(store.getters.getAddressChange) {
        store.dispatch("setAddressChange", false);
        getProduct();
      }
    });

    return {
      product,
      compatible,
      getCompatibleproducts,
      addToWatchList,
      watchlist,
      isProductLoaded,
      showReadMore,
      slugUrl,
      isLoggedIn,
      getBrandImg,
      variantImageClick,
      showOrderInfo,
      isBundlePage,
      variantGalleryItem,
      isOnMobile,
      isOnTablet,
    };
  },
};
</script>

<style lang="scss" scoped>
.product-page {
  @apply py-6 md:pt-0;
  .white-block {
    @apply bg-white rounded-lg shadow-box p-6;
    &.product-desc:not(.has-padding) {
      @apply p-0 2xl:py-6 2xl:px-8;
    }

    &.no-padding {
      @apply p-0;
    }
  }

  .product-desc {
    @apply w-full 2xl:w-auto flex-grow;
  }

  .product-section {
    @apply flex flex-col 2xl:flex-row gap-6;

    .product-info-grid {
      @apply flex flex-col flex-grow gap-6;

      .product-info {
        @apply w-full items-start flex flex-col xl:flex-row gap-6;
      }
      .product-preview {
        @apply w-full xl:w-72 flex-shrink-0 md:space-y-8;
      }

      .product-category-cards {
        @apply grid grid-cols-1 xl:grid-cols-2 gap-6;
      }
    }
  }
}
</style>

<style lang="scss">
.vel-img-title {
  @apply bg-black bg-opacity-90 text-white px-5 py-1.5 rounded text-base font-light;
}
.bundle-title {
  @apply flex items-center justify-between px-3 py-1.5 m-0 mb-4 font-medium border-b border-brand-ash;

  &.save-label {
    @apply border-0 bg-brand-indigo bg-opacity-50 rounded-full text-brand-indigodarkest font-normal text-xs flex items-center space-x-1.5 justify-start;
  }

  p {
    @apply text-black text-sm p-0 m-0;
  }
}
</style>
